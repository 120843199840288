import './styles.css'
import LoadComponent from 'components/loadComponent'
import { SearchFilter } from 'components/SearchFilter'
import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { FaRedo } from 'react-icons/fa'
import { RiArrowDownSLine } from 'react-icons/ri'

import { Pagination } from '../../components/Pages/Pagination'
import { SearchForm } from '../../components/SearchForm'
import SideBar from '../../components/SideBarLateral'
import { AuthContext } from '../../contexts/AuthContext'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'
import { DadosContext } from '../../contexts/DadosContext'
import { EnquadramentoContext } from '../../contexts/EnquadramentoContext'

function ListagemEnquadramentos() {
  const { classSideNav } = useContext(CondicionaisFormContext)
  const { Permissoes } = useContext(AuthContext)
  const { Enquadramento, valorUnidadeFinanceira, nomeUnidadeFinanceira, loadEnquadramentos, fetchEnquadramentos } =
    useContext(EnquadramentoContext)
  const { ModalidadeServico } = useContext(DadosContext)

  const [searchEnquadramento, setSearchEnquadramento] = useState('')
  const [filterSearchEnquadramento, setFilterSearchEnquadramento] = useState([])
  const [filterAlternativo, setFilterAlternativo] = useState([])
  const [filterGeral, setFilterGeral] = useState('Todos')

  const [linhasPerPage, setLinhasPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)
  const startIndex = (currentPage - 1) * linhasPerPage
  const endIndex = startIndex + linhasPerPage
  const currentEnquadramentos = filterSearchEnquadramento.slice(startIndex, endIndex)

  const [isIconOrdenaNumero, setIsIconOrdenaNumero] = useState(true)
  const [isIconOrdenaNome, setIsIconOrdenaNome] = useState(true)
  const [showIcon, setShowIcon] = useState({})
  const [showDetailsEnquadramento, setShowDetailsEnquadramento] = useState({})

  // #region filtro
  useEffect(() => {
    setCurrentPage(1)
  }, [filterGeral, filterSearchEnquadramento])

  useEffect(() => {
    setSearchEnquadramento('')
  }, [filterGeral])

  useEffect(() => {
    if (filterGeral === 'Todos') {
      const FiltroGeral = Enquadramento
      setFilterSearchEnquadramento(FiltroGeral)
    } else {
      const FiltroGeral = Enquadramento.filter((value) => value.servico.descricao === filterGeral)
      setFilterAlternativo(FiltroGeral)
      setFilterSearchEnquadramento(FiltroGeral)
    }
  }, [filterGeral, Enquadramento])

  useEffect(() => {
    setShowDetailsEnquadramento({})
  }, [currentPage])

  const onChangeBuscaEnquadramento = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearchEnquadramento(e.target.value)

    if (filterGeral === 'Todos') {
      const Filtro = Enquadramento.filter(
        (value) =>
          value.descricao?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.grupo?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.codigo?.toString().includes(e.target.value)
      )

      setFilterSearchEnquadramento(Filtro)
    } else {
      const Filtro = filterAlternativo.filter(
        (value) =>
          value.descricao?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.grupo?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.codigo?.toString().includes(e.target.value)
      )

      setFilterSearchEnquadramento(Filtro)
    }
  }

  // #endregion

  // ------------------------------------------------------------- //

  // #region icon
  const mostraIcon = (valor: number) => () => {
    setShowIcon((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  const mostraDetalhesEnquadramento = (valor: number) => () => {
    setShowDetailsEnquadramento((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }
  // #endregion

  return (
    <div className="content">
      {Permissoes.toString() === 'Administrador' ? (
        <div className="sideNavLateral">
          <SideBar />
        </div>
      ) : null}

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="listagemEnquadramentos container mt-3">
          <h2>Tabela de Enquadramentos</h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
              <SearchForm
                defaultValue={filterGeral}
                id="buscaCorporacao"
                value={searchEnquadramento}
                filterMap={ModalidadeServico}
                onChangeFilterGeral={(e) => setFilterGeral(e.target.value)}
                onChangeBusca={onChangeBuscaEnquadramento}
                defaultValueRegisters={linhasPerPage}
                onChangeRegisters={(e) => setLinhasPerPage(Number(e.target.value))}
                placeholder="Código, grupo ou descrição"
                name="buscaEnquadramento"
              />
              <button
                className="btn btn-outline-secondary ml-2"
                onClick={fetchEnquadramentos}
                disabled={loadEnquadramentos}
                style={{ position: 'absolute', right: '-45px', top: '8.5px' }}
              >
                <FaRedo />
              </button>
            </div>
          </div>
          <div className="tabelaEnquadramentos container">
            {loadEnquadramentos ? (
              <LoadComponent />
            ) : (
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className="headerTabela">
                      Código
                      {isIconOrdenaNumero ? (
                        <SearchFilter
                          ordenaDescrescente
                          onClickDecrescente={() => [
                            filterSearchEnquadramento.sort((a, b) => {
                              if (a.codigo < b.codigo) return -1
                              if (a.codigo > b.codigo) return 1
                              return 0
                            }),
                            setIsIconOrdenaNumero(false)
                          ]}
                        />
                      ) : (
                        <SearchFilter
                          ordenaDescrescente={false}
                          onClickCrescente={() => [
                            filterSearchEnquadramento.sort((a, b) => {
                              if (a.codigo < b.codigo) return 1
                              if (a.codigo > b.codigo) return -1
                              return 0
                            }),
                            setIsIconOrdenaNumero(true)
                          ]}
                        />
                      )}
                    </th>
                    <th className="headerTabela">
                      <span>
                        Grupo
                        {isIconOrdenaNome ? (
                          <SearchFilter
                            ordenaDescrescente
                            onClickDecrescente={() => {
                              filterSearchEnquadramento.sort((a, b) => {
                                if (a.grupo < b.grupo) return -1
                                if (a.grupo > b.grupo) return 1
                                return 0
                              })
                              setIsIconOrdenaNome(false)
                            }}
                          />
                        ) : (
                          <SearchFilter
                            ordenaDescrescente={false}
                            onClickCrescente={() => [
                              filterSearchEnquadramento.sort((a, b) => {
                                if (a.grupo < b.grupo) return 1
                                if (a.grupo > b.grupo) return -1
                                return 0
                              }),
                              setIsIconOrdenaNome(true)
                            ]}
                          />
                        )}
                      </span>
                    </th>

                    <th className="headerTabela">
                      <span>Tipo de serviço</span>
                    </th>

                    <th className="lastHeaderTabela">
                      <span>Descrição</span>
                    </th>
                  </tr>
                </thead>
                {currentEnquadramentos.map((dado, valor) => {
                  const descricaoResumida = dado.descricao.slice(0, 50)
                  return (
                    <tbody key={dado.id}>
                      <tr
                        className="linhaEnquadrameto"
                        onMouseEnter={mostraIcon(valor)}
                        onMouseLeave={mostraIcon(valor)}
                      >
                        <td className="dadoTabelaCodigo">
                          <div className="textNomeEnquadramento">{dado.codigo}</div>
                        </td>

                        <td className="dadoTabela">
                          <div className="textNomeEnquadramento">{dado.grupo}</div>
                        </td>

                        <td className="dadoTabela">
                          <div className="textNomeEnquadramento">{dado.servico.descricao}</div>
                        </td>

                        <td className="dadoTabelaDescricao">
                          <div className="textDescricaoEnquadramento">
                            {dado.descricao.length >= 50 ? `${descricaoResumida}...` : dado.descricao}
                          </div>

                          {showIcon[valor] ? (
                            <div className="iconeExpandeInfracao">
                              <RiArrowDownSLine size={20} onClick={mostraDetalhesEnquadramento(valor)} />
                            </div>
                          ) : null}
                        </td>
                      </tr>

                      {showDetailsEnquadramento[valor] ? (
                        <tr>
                          <td colSpan={4}>
                            <div className="detalhesEnquadramento">
                              <h6 className="titleDetailsEnquadramento container">Detalhes do enquadramento:</h6>
                              <ul className="listaDetalhesEnquadramento">
                                <li>
                                  <span className="itemListaDetalhesEnquadramento">- Descrição:</span>
                                  {` ${dado.descricao}`}
                                </li>

                                <li>
                                  <span className="itemListaDetalhesEnquadramento">- Valor unitário:</span>
                                  {` R$ ${dado.unidade * valorUnidadeFinanceira} 
                                          [${dado.unidade} 
                                          ${nomeUnidadeFinanceira} 
                                          x R$ ${valorUnidadeFinanceira}]`}
                                </li>

                                {dado.medidasAdministrativas.length !== 0 ? (
                                  <li>
                                    <span className="itemListaDetalhesEnquadramento">- Medidas administrativas:</span>
                                    {dado.medidasAdministrativas.map((medidas) => (
                                      <ul key={medidas.id} className="listaMedidasAdministrativas">
                                        <li>- {medidas.descricao}</li>
                                      </ul>
                                    ))}
                                  </li>
                                ) : null}

                                {dado.tipoGravidade.descricao !== 'N/A' ? (
                                  <li className="mt-1">
                                    <span className="itemListaDetalhesEnquadramento">- Tipo de gravidade:</span>
                                    {` ${dado.tipoGravidade.descricao}`}
                                  </li>
                                ) : null}

                                {dado.penalidade !== '' ? (
                                  <li className="mt-1">
                                    <span className="itemListaDetalhesEnquadramento">- Penalidade:</span>
                                    {` ${dado.penalidade}`}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  )
                })}
              </Table>
            )}
          </div>

          {!loadEnquadramentos && (
            <Pagination
              currentPage={currentPage}
              registersPerPage={linhasPerPage}
              onPageChange={setCurrentPage}
              textNoContent="Nenhum enquadramento encontrado"
              totalCountOfRegisters={filterSearchEnquadramento.length}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ListagemEnquadramentos
