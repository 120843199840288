import { yupResolver } from '@hookform/resolvers/yup'
import Tooltip from '@mui/material/Tooltip'
import { DatePicker, Space } from 'antd'
import type { DatePickerProps } from 'antd'
import ButtonSubmit from 'components/ButtonSubmit'
import { ErrorForm } from 'components/FormTalao/ErrorForm'
import ModalMessage from 'components/Modais/ModalMessage'
import { CondicionaisFormContext } from 'contexts/CondicionaisFormContext'
import { DadosContext } from 'contexts/DadosContext'
import { PermissionariosContext } from 'contexts/PermissionariosContext'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiArrowBack } from 'react-icons/bi'
import { CadastroVeiculo } from 'services/permissionariosService'
import * as yup from 'yup'

function CadastroVeiculos() {
  const {
    cor,
    ModalidadeServico,
    onChangeMarcaModelo,
    filterMarcaModelo,
    textMarcaModelo,
    setTextMarcaModelo,
    idMarcaModelo,
    selecionaMarcaModelo,
    atualizaPermissionarios
  } = useContext(DadosContext)

  const { voltaTabela } = useContext(CondicionaisFormContext)

  const [successCadastroVeiculo, setSuccessCadastroVeiculo] = useState(false)
  const [falhaCadastroVeiculo, setFalhaCadastroVeiculo] = useState(false)
  const [messageCadastroVeiculo, setMessageCadastroVeiculo] = useState([])

  const [anoLicenciamentoNovoVeiculo, setAnoLicenciamentoNovoVeiculo] = useState('')
  const [anoModeloNovoVeiculo, setAnoModeloNovoVeiculo] = useState('')
  const [requiredAnoLicenciamentoNovoVeiculo, setRequiredAnoLicenciamentoNovoVeiculo] = useState(false)
  const [requiredAnoModeloNovoVeiculo, setRequiredAnoModeloNovoVeiculo] = useState(false)

  const validaCriacaoVeiculo = yup.object({
    placa: yup.string().required(),
    prefixo: yup.string().required(),
    renavam: yup.string().required(),
    marcaModelo: yup.string().required(),
    numeroChassi: yup.string().required(),
    tipoServicoId: yup.number().required(),
    corId: yup.number().required()
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validaCriacaoVeiculo)
  })

  function submitCadastroVeiculo() {
    setSuccessCadastroVeiculo(true)
    setAnoLicenciamentoNovoVeiculo('')
    setAnoModeloNovoVeiculo('')
    setTextMarcaModelo('')
    atualizaPermissionarios()
  }

  const formCadastraVeiculo = (data) => {
    if (!requiredAnoLicenciamentoNovoVeiculo && !requiredAnoModeloNovoVeiculo) {
      CadastroVeiculo(data, idMarcaModelo, Number(anoLicenciamentoNovoVeiculo), Number(anoModeloNovoVeiculo), null)
        .then((response) => {
          if (response.status === 200) {
            setMessageCadastroVeiculo([response.data.message])
            submitCadastroVeiculo()
          }
        })
        .catch((error) => {
          if (error.response.status === 400 || error.response.status === 500) {
            if (error.response.data.message) {
              setMessageCadastroVeiculo([error.response.data.message])
              setFalhaCadastroVeiculo(true)
            } else {
              const mensagensErro = Object.values(error.response.data.errors)
              setMessageCadastroVeiculo(mensagensErro.map((mensagens) => mensagens.toString()))
              setFalhaCadastroVeiculo(true)
            }
          }
        })
    }
  }

  const changeAnoLicenciamento: DatePickerProps['onChange'] = (date) => {
    if (date !== null) {
      setAnoLicenciamentoNovoVeiculo(date.year().toString())
    } else {
      setAnoLicenciamentoNovoVeiculo('')
    }
  }

  const changeAnoModelo: DatePickerProps['onChange'] = (date) => {
    if (date !== null) {
      setAnoModeloNovoVeiculo(date.year().toString())
    } else {
      setAnoModeloNovoVeiculo('')
    }
  }

  function validaInputsWYears() {
    if (anoLicenciamentoNovoVeiculo === '' && anoModeloNovoVeiculo === '') {
      setRequiredAnoLicenciamentoNovoVeiculo(true)
      setRequiredAnoModeloNovoVeiculo(true)
    }

    if (anoLicenciamentoNovoVeiculo === '') {
      setRequiredAnoLicenciamentoNovoVeiculo(true)
    } else if (anoModeloNovoVeiculo === '') {
      setRequiredAnoModeloNovoVeiculo(true)
    } else {
      return null
    }
  }

  useEffect(() => {
    if (anoLicenciamentoNovoVeiculo !== '') {
      setRequiredAnoLicenciamentoNovoVeiculo(false)
    }

    if (anoModeloNovoVeiculo !== '') {
      setRequiredAnoModeloNovoVeiculo(false)
    }
  }, [anoLicenciamentoNovoVeiculo, anoModeloNovoVeiculo])

  return (
    <div className="cadastro novoRegistro">
      <form className="criaRegistro mb-4" onSubmit={handleSubmit(formCadastraVeiculo)} autoComplete="off">
        <div className="subtitulo mb-2">
          <h6 className="mt-1" onClick={() => setTextMarcaModelo('')}>
            Cadastrar novo veículo
          </h6>
        </div>

        <div className="formCriaRegistro">
          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="placaNovoVeiculo"
              id="placaNovoVeiculo"
              placeholder="Placa"
              maxLength={7}
              {...register('placa')}
            />
            <label htmlFor="placaNovoVeiculo">Placa</label>
            {errors?.placa?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="prefixoNovoVeiculo"
              id="prefixoNovoVeiculo"
              placeholder="Prefixo"
              {...register('prefixo')}
            />
            <label htmlFor="prefixoNovoVeiculo">Prefixo</label>
            {errors?.prefixo?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="renavamNovoVeiculo"
              id="renavamNovoVeiculo"
              maxLength={11}
              placeholder="Renavam"
              {...register('renavam')}
            />
            <label htmlFor="renavamNovoVeiculo">Renavam</label>
            {errors?.renavam?.type && <ErrorForm />}
          </div>
          <Space direction="vertical" className="selectYears col-3">
            <DatePicker
              value={anoLicenciamentoNovoVeiculo !== '' && dayjs(anoLicenciamentoNovoVeiculo)}
              onChange={changeAnoLicenciamento}
              format="YYYY"
              picker="year"
              size="large"
              className="datePicker col-12"
              placeholder="Ano de Licenciamento"
            />
            {requiredAnoLicenciamentoNovoVeiculo && <ErrorForm />}
          </Space>

          <Space direction="vertical" className="selectYears col-3" id="selectYears">
            <DatePicker
              value={anoModeloNovoVeiculo !== '' && dayjs(anoModeloNovoVeiculo)}
              onChange={changeAnoModelo}
              format="YYYY"
              name="anoModeloNovoVeiculo"
              id="anoModeloNovoVeiculo"
              picker="year"
              size="large"
              className="datePicker col-12"
              placeholder="Ano de Modelo"
            />
            {requiredAnoModeloNovoVeiculo && <ErrorForm />}
          </Space>

          <div className="labelForm col-11 col-md-4">
            <input
              type="text"
              name="marcaModeloNovoVeiculo"
              id="marcaModeloNovoVeiculo"
              className="form-control"
              placeholder="Marca/ Modelo"
              {...register('marcaModelo')}
              value={textMarcaModelo}
              onChange={onChangeMarcaModelo}
            />
            <label htmlFor="marcaModelo"> Marca/ Modelo </label>

            {filterMarcaModelo.length > 0 && (
              <div className="optionsMunicipios col-12">
                {filterMarcaModelo.map((dado) => (
                  <div className="listaMunicipios" key={dado.id} onClick={() => selecionaMarcaModelo(dado)}>
                    {dado.descricao}
                  </div>
                ))}
              </div>
            )}
            {errors?.marcaModelo?.type && textMarcaModelo.length === 0 && <ErrorForm />}
          </div>

          <div className="labelForm col-4">
            <select
              name="tipoServicoNovoVeiculo"
              className="col-12"
              defaultValue="Tipo de serviço"
              {...register('tipoServicoId')}
            >
              <option disabled>Tipo de serviço</option>
              {ModalidadeServico.map((servico) => (
                <option key={servico.id} value={servico.id}>
                  {servico.descricao}
                </option>
              ))}
            </select>
            {errors?.tipoServicoId?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="numeroChassiNovoVeiculo"
              maxLength={17}
              id="numeroChassiNovoVeiculo"
              placeholder="Chassi"
              {...register('numeroChassi')}
            />
            <label htmlFor="numeroChassiNovoVeiculo">Chassi</label>
            {errors?.numeroChassi?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <select name="corNovoVeiculo" className="col-12" defaultValue="Cor" {...register('corId')}>
              <option disabled>Cor</option>
              {cor.map((dado) => (
                <option key={dado.id} value={dado.id}>
                  {dado.descricao}
                </option>
              ))}
            </select>
            {errors?.corId?.type && <ErrorForm />}
          </div>

          <div className="buttonCriaRegistro col-12">
            <div className="iconVoltar">
              <Tooltip title="Voltar a consulta" placement="bottom" arrow>
                <div>
                  <BiArrowBack size={25} onClick={voltaTabela} className="iconNavegacao" />
                </div>
              </Tooltip>
            </div>
            <ButtonSubmit type="submit" text="Cadastrar" onClick={validaInputsWYears} />
          </div>

          <ModalMessage
            title={messageCadastroVeiculo}
            className="modalSuccess"
            show={successCadastroVeiculo}
            onHide={() => [setSuccessCadastroVeiculo(false), reset()]}
            textbutton="Voltar ao registro de Veículo"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageCadastroVeiculo}
            className="modalFalha"
            show={falhaCadastroVeiculo}
            onHide={() => setFalhaCadastroVeiculo(false)}
            textbutton="Tentar novamente"
            textbutton2="Ir para o ínicio"
          />
        </div>
      </form>
    </div>
  )
}

export default CadastroVeiculos
